import './style/global.less';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import { ConfigProvider } from '@arco-design/web-react';
import zhCN from '@arco-design/web-react/es/locale/zh-CN';
import enUS from '@arco-design/web-react/es/locale/en-US';
import { BrowserRouter, Switch, Route, useHistory, useLocation } from 'react-router-dom';
import PageLayout from './layout';
import { GlobalContext } from './context';
import Login from './pages/login';
import Register from './pages/register';
import useStorage from './utils/useStorage';
import { isJwtTokenExpired } from '@/utils/isJwtTokenExpired'
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '@/reduxStore';
import { GlobalState } from '@/store';

function App() {
  const [lang, setLang] = useStorage('arco-lang', 'en-US');
  const [theme, setTheme] = useStorage('arco-theme', 'light');
  const { jwtToken, stateLoading } = useSelector((state: GlobalState) => state);
  const location = useLocation();
  const history = useHistory();

  function getArcoLocale() {
    switch (lang) {
      case 'zh-CN':
        return zhCN;
      case 'en-US':
        return enUS;
      default:
        return zhCN;
    }
  }

  useEffect(() => {
    if (stateLoading) {
      return;
    }

    const isLoggedIn = !isJwtTokenExpired(jwtToken);
    const isProtectedRoute: boolean = location.pathname !== '/login' && location.pathname !== '/register';

    if (!isLoggedIn && isProtectedRoute) {
      history.push('/login');
    } else if (isLoggedIn && !isProtectedRoute) {
      history.push('/');
    }

  }, [history, location.pathname, jwtToken, stateLoading]);

  useEffect(() => {
    if (theme === 'dark') {
      document.body.setAttribute('arco-theme', 'dark');
    } else {
      document.body.removeAttribute('arco-theme');
    }
  }, [theme]);

  const contextValue = {
    lang,
    setLang,
    theme,
    setTheme,
  };

  return (
    <ConfigProvider
      locale={getArcoLocale()}
      componentConfig={{
        Card: {
          bordered: false,
        },
        List: {
          bordered: false,
        },
        Table: {
          border: false,
        },
      }}
    >
      <PersistGate loading={null} persistor={persistor}>
        <GlobalContext.Provider value={contextValue}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route path="/" component={PageLayout} />
          </Switch>
        </GlobalContext.Provider>
      </PersistGate>
    </ConfigProvider>
  );
}

function Index() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
}

ReactDOM.render(<Index />, document.getElementById('root'));
