import auth, { AuthParams } from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';

export type IRoute = AuthParams & {
  name: string;
  key: string;
  // 当前页是否展示面包屑
  breadcrumb?: boolean;
  children?: IRoute[];
  // 当前路由是否渲染菜单项，为 true 的话不会在菜单中显示，但可通过路由地址访问。
  ignore?: boolean;
};

export const routes: IRoute[] = [
  {
    name: 'menu.dashboard',
    key: 'dashboard'
  },
  // hide applicant for 3rd party temporarily
  // {
  //   name: 'menu.applicants',
  //   key: 'applicants',
  //   children: [
  //     {
  //       name: 'menu.applicants.create',
  //       key: 'applicants/create',
  //       // requiredPermissions: [
  //       //   { resource: 'menu.order.new', actions: ['read', 'write'] },
  //       // ],
  //     },
  //     {
  //       name: 'menu.applicants.detail',
  //       key: 'applicants/detail',
  //       // requiredPermissions: [
  //       //   { resource: 'menu.order.new', actions: ['read', 'write'] },
  //       // ],
  //     },
  //   ],
  // },
  {
    name: 'menu.order',
    key: 'order',
    children: [
      {
        name: 'menu.order.query',
        key: 'order/query',
        // requiredPermissions: [
        //   { resource: 'menu.order.query', actions: ['read', 'write'] },
        // ],
      },
      {
        name: 'menu.order.manual.new',
        key: 'order/manual-create',
        // requiredPermissions: [
        //   { resource: 'menu.order.new', actions: ['read', 'write'] },
        // ],
      },
      {
        name: 'menu.order.upload.new',
        key: 'order/upload-create',
        // requiredPermissions: [
        //   { resource: 'menu.order.new', actions: ['read', 'write'] },
        // ],
      },
    ],
  },
  {
    name: 'menu.setting',
    key: 'setting',
    children: [
      {
        name: 'menu.setting.user',
        key: 'setting/user',
      },
      {
        name: 'menu.setting.agency',
        key: 'setting/agency',
      },
    ],
    ignore: true
  },
];

export const getName = (path: string, routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};

export const generatePermission = (role: string) => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach((item) => {
    if (item.children) {
      item.children.forEach((child) => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};

const useRoute = (userPermission): [IRoute[], string] => {
  const filterRoute = (routes: IRoute[], arr = []): IRoute[] => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const { requiredPermissions, oneOfPerm } = route;
      let visible = true;
      if (requiredPermissions) {
        visible = auth({ requiredPermissions, oneOfPerm }, userPermission);
      }

      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = { ...route, children: [] };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route });
      }
    }

    return arr;
  };

  const [permissionRoute, setPermissionRoute] = useState(routes);

  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
    /* eslint-disable-next-line */
  }, [JSON.stringify(userPermission)]);

  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return '';
  }, [permissionRoute]);

  return [permissionRoute, defaultRoute];
};

export default useRoute;
