export const getVisaCountry = (type: number) => {
  switch (type) {
    case 1:
      return "France";
    case 2:
      return "Spain";
    default:
      return "Unspecified";
  }
}

export const getVisaType = (type: number) => {
  switch (type) {
    case 1:
      return "Short";
    case 2:
      return "Long";
    default:
      return "Unspecified";
  }
}

export const getConsularDistrict = (district: number) => {
  switch (district) {
    case 1:
      return "Edinburgh";
    case 2:
      return "London";
    case 3:
      return "Manchester";
    default:
      return "Unspecified";
  }
}

export const getOrderStatus = (type: number) => {
  switch (type) {
    case 1:
      return "Pending";
    case 2:
      return "Suspended";
    case 3:
      return "Working";
    case 4:
      return "Cancelled";
    case 5:
      return "Submitted";
    case 6:
      return "Failed";
    case 7:
      return "Finished";
    default:
      return "Unspecified";
  }
}

export const getAgencyType = (type: number) => {
  switch (type) {
    case 1:
      return "Local";
    case 2:
      return "Taobao";
    case 3:
      return "Redbook";
    case 4:
      return "ThirdParty"
    default:
      return "Unspecified";
  }
}

export const getSlotType = (type: number) => {
  switch (type) {
    case 1:
      return "Weekday Normal";
    case 2:
      return "Weekday Prime";
    case 3:
      return "Weekend";
    default:
      return "Unspecified";
  }
}

export const getPriority = (district: number) => {
  switch (district) {
    case 1:
      return "Normal";
    case 2:
      return "Urgent";
    default:
      return "Unspecified";
  }
}

export const OrderStatusType = ['Pending', 'Suspended', 'Working', 'Cancelled', 'Submitted', 'Failed', 'Finished'];
export const PriorityType = ['Normal', 'Urgent'];
export const VisaTypeType = ['Short', 'Long'];
export const VisaCountryType = ['France', 'Spain'];
export const AgencyType = ['Local', 'Taobao', 'ThirdParty'];
export const ConsularDistrictType = ['Edinburgh', 'London', 'Manchester'];
export const SlotType = ['Weekday Normal', 'Weekday Prime', 'Weekend'];