
const base64DecodeUnicode = (str: string) => {
  // Convert Base64 encoded bytes to percent-encoding, and then get the original string.
  return decodeURIComponent(atob(str).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

export const isJwtTokenExpired = (token?: string) => {
  if (!token) {
    return true;
  }

  try {
    const parts = token.split('.');
    const payload = JSON.parse(base64DecodeUnicode(parts[1]));
    const exp = payload.exp;
    const now = Math.floor(Date.now() / 1000);
    return now > exp;
  } catch (e) {
    console.log(e.message);
    return true;
  }
}
