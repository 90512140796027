const i18n = {
  'en-US': {
    'login.form.title': 'Login to VisaHub',
    'login.form.email.errMsg': 'Email cannot be empty',
    'login.form.password.errMsg': 'Password cannot be empty',
    'login.form.login.errMsg': 'Login error, please refresh and try again',
    'login.form.email.placeholder': 'Please enter your email address',
    'login.form.password.placeholder': 'Please enter your password',
    'login.form.rememberPassword': 'Remember password',
    'login.form.error': 'Login Error',
    'login.form.login': 'Login',
    'login.form.register': 'Register account',
    'login.banner.slogan': 'VisaHub',
    'login.banner.subSlogan': 'No hassle, just travel'
  },
  'zh-CN': {
    'login.form.title': '登录 VisaHub',
    'login.form.email.errMsg': '邮箱地址不能为空',
    'login.form.password.errMsg': '密码不能为空',
    'login.form.login.errMsg': '登录出错，请刷新重试',
    'login.form.email.placeholder': '请输入邮箱地址',
    'login.form.password.placeholder': '请输入您的密码',
    'login.form.rememberPassword': '记住密码',
    'login.form.error': '账号登录错误',
    'login.form.login': '登录',
    'login.form.register': '注册账号',
    'login.banner.slogan': 'VisaHub',
    'login.banner.subSlogan': '无烦扰，尽畅游'
  },
};

export default i18n;
