import React, { useEffect, useState } from 'react';
import {
  Card,
  Steps,
  Typography,
  Grid,
  Space,
  Button,
  Message
} from '@arco-design/web-react';
import { useParams, useHistory } from 'react-router-dom';
import { IconPause, IconPlayArrow, IconStop } from '@arco-design/web-react/icon';
import useLocale from '@/utils/useLocale';
import locale from './locale';
import SlotItem from './slotItem';
import styles from './style/index.module.less';
import { platform } from '@/utils/requestPlaform';
import { QueryOrderDetailRequest, ChangeOrderStateRequest } from '@/api-lib/platform';
import { useSelector } from 'react-redux';
import { GlobalState } from '@/store';

function BasicProfile() {
  const t = useLocale(locale);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [resumeOrderLoading, setResumeOrderLoading] = useState(false);
  const [cancelOrderLoading, setCancelOrderLoading] = useState(false);
  const [suspendOrderLoading, setSuspendOrderLoading] = useState(false);
  const { jwtToken } = useSelector((state: GlobalState) => state);

  const [data, setData] = useState<{
    order: {
      /* eslint-disable-next-line */
      slots: any[];

      visa_email?: string;
      visa_username?: string;
    },
    /* eslint-disable-next-line */
    user?: any,
    order_status?: number;
    priority?: number;
  }>({
    order: {
      slots: [],
    },
    user: {},
    order_status: 1,
  });

  async function fetchData() {
    setLoading(true);
    setIsError(false);

    const request: QueryOrderDetailRequest = {
      id: id,
      "X-Jwt-Token": jwtToken
    }

    try {
      const response = await platform.QueryOrderDetail(request);
      setData(response.data)
      setLoading(false);
    } catch (e) {
      setIsError(true);
      setLoading(false);

      let errorMsg = '';
      if (e.response) {
        const json = await e.response.json();
        if (json.message) {
          errorMsg += ' ' + json.message;
        }
      }
      console.log(errorMsg);
      Message.error({ content: errorMsg, duration: 6000 });
    }
  }

  async function suspendOrder() {
    setSuspendOrderLoading(true);

    const request: ChangeOrderStateRequest = {
      id: id,
      "X-Jwt-Token": jwtToken
    }

    try {
      await platform.SuspendOrder(request);
      setSuspendOrderLoading(false);

      Message.success(t['order.detail.suspend.success']);
      setTimeout(() => {
        history.go(0);
      }, 1000);
    } catch (e) {
      setSuspendOrderLoading(false);
      let errorMsg = t['order.detail.suspend.fail'];
      if (e.response) {
        const json = await e.response.json();
        if (json.message) {
          errorMsg += ' ' + json.message;
        }
      }
      console.log(errorMsg);
      Message.error({ content: errorMsg, duration: 6000 });
    }
  }

  async function resumeOrder() {
    setResumeOrderLoading(true);

    const request: ChangeOrderStateRequest = {
      id: id,
      "X-Jwt-Token": jwtToken
    }

    try {
      await platform.ResumeOrder(request);
      setResumeOrderLoading(false);

      Message.success(t['order.detail.resume.success']);
      setTimeout(() => {
        history.go(0);
      }, 1000);
    } catch (e) {
      setResumeOrderLoading(false);
      let errorMsg = t['order.detail.resume.fail'];
      if (e.response) {
        const json = await e.response.json();
        if (json.message) {
          errorMsg += ' ' + json.message;
        }
      }
      console.log(errorMsg);
      Message.error({ content: errorMsg, duration: 6000 });
    }
  }

  async function cancelOrder() {
    setCancelOrderLoading(true);

    const request: ChangeOrderStateRequest = {
      id: id,
      "X-Jwt-Token": jwtToken
    }

    try {
      await platform.CancelOrder(request);
      setCancelOrderLoading(false);

      Message.success(t['order.detail.cancel.success']);
      setTimeout(() => {
        history.go(0);
      }, 1000);
    } catch (e) {
      setCancelOrderLoading(false);
      let errorMsg = t['order.detail.cancel.fail'];
      if (e.response) {
        const json = await e.response.json();
        if (json.message) {
          errorMsg += ' ' + json.message;
        }
      }
      console.log(errorMsg);
      Message.error({ content: errorMsg, duration: 6000 });
    }
  }

  useEffect(() => {
    fetchData();
    /* eslint-disable-next-line */
  }, []);

  const orderStatusMapper = (order_status: number) => {
    switch (order_status) {
      case 1:
        return 'process';
      case 2:
        return 'error';
      case 3:
        return 'process';
      case 4:
        return 'error';
      case 5:
        return 'process';
      case 6:
        return 'error';
      case 7:
        return 'finish';
      default:
        return 'wait';
    }
  }

  const currentStepMapper = (order_status: number) => {
    switch (order_status) {
      case 1:
        return 1
      case 2:
        return 2
      case 3:
        return 2
      case 4:
        return 2
      case 5:
        return 2
      case 6:
        return 2;
      case 7:
        return 3;
      default:
        return 1;
    }
  }

  const reasonMapper = (order_status: number) => {
    switch (order_status) {
      case 1:
        return t['order.status.working'];
      case 2:
        return t['order.status.suspended'];
      case 3:
        return t['order.status.working'];
      case 4:
        return t['order.status.cancelled'];
      case 5:
        return t['order.status.submitted'];
      case 6:
        return t['order.status.failed'];
      case 7:
        return t['order.status.finished'];
      default:
        return t['order.status.working'];
    }
  }

  return isError ? <Card /> : (
    <div className={styles.container}>
      <Card>
        <Grid.Row justify="space-between" align="center">
          <Grid.Col span={16}>
            <Typography.Title heading={6}>
              {t['order.detail.status']}
            </Typography.Title>
          </Grid.Col>
          <Grid.Col span={8} style={{ textAlign: 'right' }}>
            <div className={styles['button-group']}>
              <Space>
                {data.order_status === 1 &&
                  <Button
                    type="default"
                    status='danger'
                    icon={<IconPause />}
                    onClick={suspendOrder}
                    loading={suspendOrderLoading}>
                    {t['order.detail.suspend']}
                  </Button>}

                {data.order_status === 1 && <Button
                  type="primary"
                  status='danger'
                  icon={<IconStop />}
                  onClick={cancelOrder}
                  loading={cancelOrderLoading}>
                  {t['order.detail.cancel']}
                </Button>}
              </Space>
              <Space>
                {(data.order_status === 2 || data.order_status === 4) &&
                  <Button
                    type="default"
                    status='success'
                    icon={<IconPlayArrow />}
                    onClick={resumeOrder}
                    loading={resumeOrderLoading}>
                    {t['order.detail.resume']}
                  </Button>}
              </Space>
            </div>
          </Grid.Col>
        </Grid.Row>

        <Steps
          current={currentStepMapper(data.order_status)}
          className={styles.steps}
          status={orderStatusMapper(data.order_status)}>
          <Steps.Step title={t['order.status.pending']} />
          {data.order_status !== 1 && <Steps.Step title={reasonMapper(data.order_status)} />}
        </Steps>
      </Card>

      <SlotItem
        cardTitle={t['order.detail.slot']}
        title={t['order.detail.title']}
        data={data.order}
        user={data.user}
        loading={loading}
        order_status={data.order_status}
        priority={data.priority}
      />
    </div>
  );
}

export default BasicProfile;
