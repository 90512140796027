/* eslint-disable */
// @ts-nocheck

import "whatwg-fetch";

import * as enums from "./enums";
import * as po from "./po";
import * as vo from "./vo";
export { enums, po, vo };

import {
  method,
  headers,
  credentials,
  handleResponse,
  queryStringify,
} from "./_util";

export class Platform {
  constructor(uriPrefix) {
    this.uriPrefix =
      typeof uriPrefix !== "undefined" ? uriPrefix : "https://api.visahub.top";
  }

  SignUp(req) {
    const uri = `${this.uriPrefix}/api/v1/account/signup`;
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  SignIn(req) {
    const uri = `${this.uriPrefix}/api/v1/account/signin`;
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  SignOut(req = {}) {
    const uri = `${this.uriPrefix}/api/v1/account/signout`;
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  Recover(req) {
    const uri = `${this.uriPrefix}/api/v1/account/recover`;
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  Reset(req) {
    const uri = `${this.uriPrefix}/api/v1/account/reset`;
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  CreateAgency(req) {
    const uri = `${this.uriPrefix}/api/v1/agency`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  QueryAgencyList(req) {
    const query = queryStringify(req);
    const uri = `${this.uriPrefix}/api/v1/agency${query}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    return fetch(uri, { method, headers, credentials }).then(handleResponse);
  }

  QueryAgencyDetail(req) {
    const uri = `${this.uriPrefix}/api/v1/agency/${req.id}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    return fetch(uri, { method, headers, credentials }).then(handleResponse);
  }

  UpdateAgency(req) {
    const uri = `${this.uriPrefix}/api/v1/agency/${req.id}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "PUT", headers, body, credentials }).then(
      handleResponse
    );
  }

  CreateUser(req) {
    const uri = `${this.uriPrefix}/api/v1/user`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  QueryUserList(req) {
    const query = queryStringify(req);
    const uri = `${this.uriPrefix}/api/v1/user${query}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    return fetch(uri, { method, headers, credentials }).then(handleResponse);
  }

  QueryUserDetail(req) {
    const uri = `${this.uriPrefix}/api/v1/user/${req.id}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    return fetch(uri, { method, headers, credentials }).then(handleResponse);
  }

  UpdateUser(req) {
    const uri = `${this.uriPrefix}/api/v1/user/${req.id}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "PUT", headers, body, credentials }).then(
      handleResponse
    );
  }

  UpdatePassword(req) {
    const uri = `${this.uriPrefix}/api/v1/user/${req.id}/password`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "PATCH", headers, body, credentials }).then(
      handleResponse
    );
  }

  GrantAuthority(req) {
    const uri = `${this.uriPrefix}/api/v1/user/${req.id}/authority`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  RevokeAuthority(req) {
    const uri = `${this.uriPrefix}/api/v1/user/${req.id}/authority`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "DELETE", headers, body, credentials }).then(
      handleResponse
    );
  }

  DeleteUser(req) {
    const uri = `${this.uriPrefix}/api/v1/user/${req.id}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    return fetch(uri, { method: "DELETE", headers, credentials }).then(
      handleResponse
    );
  }

  CreateApplicant(req) {
    const uri = `${this.uriPrefix}/api/v1/applicant`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  UpdateApplicant(req) {
    const uri = `${this.uriPrefix}/api/v1/applicant/${req.id}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "PUT", headers, body, credentials }).then(
      handleResponse
    );
  }

  DeleteApplicant(req) {
    const uri = `${this.uriPrefix}/api/v1/applicant/${req.id}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    return fetch(uri, { method: "DELETE", headers, credentials }).then(
      handleResponse
    );
  }

  QueryApplicantList(req) {
    const query = queryStringify(req);
    const uri = `${this.uriPrefix}/api/v1/applicant${query}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    return fetch(uri, { method, headers, credentials }).then(handleResponse);
  }

  QueryApplicantDetail(req) {
    const uri = `${this.uriPrefix}/api/v1/applicant/${req.id}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    return fetch(uri, { method, headers, credentials }).then(handleResponse);
  }

  SaveSlotTimes(req) {
    const uri = `${this.uriPrefix}/api/v1/slot/time`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  QuerySlotTimes(req) {
    const query = queryStringify(req);
    const uri = `${this.uriPrefix}/api/v1/slot/time${query}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    return fetch(uri, { method, headers, credentials }).then(handleResponse);
  }

  QuerySlotList(req) {
    const query = queryStringify(req);
    const uri = `${this.uriPrefix}/api/v1/slot${query}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    return fetch(uri, { method, headers, credentials }).then(handleResponse);
  }

  CreateSlotLog(req) {
    const uri = `${this.uriPrefix}/api/v1/slot/log`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  QuerySlotLogList(req) {
    const query = queryStringify(req);
    const uri = `${this.uriPrefix}/api/v1/slot/log${query}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method, headers, body, credentials }).then(
      handleResponse
    );
  }

  DownloadTemplate(req) {
    const query = queryStringify(req);
    const uri = `${this.uriPrefix}/api/v1/order/template${query}`;
    return fetch(uri, { method, headers, credentials }).then(handleResponse);
  }

  ImportOrder(req) {
    const query = queryStringify({
      agency: req.agency,
      visa_country: req.visa_country,
    });
    const uri = `${this.uriPrefix}/api/v1/order/import${query}`;
    const headers = { "X-Jwt-Token": req["X-Jwt-Token"] };
    const body = new FormData();
    req.File && body.append("File", req.File);
    (req.tls_forms || []).forEach((item) => {
      body.append("tls_forms[]", item);
    });
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  CreateOrder(req) {
    const uri = `${this.uriPrefix}/api/v1/order`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  BindOrder(req) {
    const uri = `${this.uriPrefix}/api/v1/order/bind`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  AttachOrder(req) {
    const uri = `${this.uriPrefix}/api/v1/order/attach`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  QueryOrderList(req) {
    const query = queryStringify(req);
    const uri = `${this.uriPrefix}/api/v1/order${query}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    return fetch(uri, { method, headers, credentials }).then(handleResponse);
  }

  QueryOrderDetail(req) {
    const query = queryStringify(req);
    const uri = `${this.uriPrefix}/api/v1/order/${req.id}${query}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    return fetch(uri, { method, headers, credentials }).then(handleResponse);
  }

  UpdateOrder(req) {
    const uri = `${this.uriPrefix}/api/v1/order/${req.id}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "PUT", headers, body, credentials }).then(
      handleResponse
    );
  }

  MutateOrder(req) {
    const uri = `${this.uriPrefix}/api/v1/order/${req.id}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "PATCH", headers, body, credentials }).then(
      handleResponse
    );
  }

  SuspendOrder(req) {
    const uri = `${this.uriPrefix}/api/v1/order/${req.id}/suspend`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "PATCH", headers, body, credentials }).then(
      handleResponse
    );
  }

  CancelOrder(req) {
    const uri = `${this.uriPrefix}/api/v1/order/${req.id}/cancel`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "PATCH", headers, body, credentials }).then(
      handleResponse
    );
  }

  ResumeOrder(req) {
    const uri = `${this.uriPrefix}/api/v1/order/${req.id}/resume`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "PATCH", headers, body, credentials }).then(
      handleResponse
    );
  }

  SubmitOrder(req) {
    const uri = `${this.uriPrefix}/api/v1/order/${req.id}/submit`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "PATCH", headers, body, credentials }).then(
      handleResponse
    );
  }

  FinishOrder(req) {
    const uri = `${this.uriPrefix}/api/v1/order/${req.id}/finish`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "PATCH", headers, body, credentials }).then(
      handleResponse
    );
  }

  DownloadOrder(req) {
    const query = queryStringify(req);
    const uri = `${this.uriPrefix}/api/v1/order/download${query}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    return fetch(uri, { method, headers, credentials }).then(handleResponse);
  }

  VisualizeOrder(req) {
    const uri = `${this.uriPrefix}/api/v1/visualize/order`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  CreateGhost(req) {
    const uri = `${this.uriPrefix}/api/v1/ghost`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  UpdateGhost(req) {
    const uri = `${this.uriPrefix}/api/v1/ghost/${req.id}`;
    const body = JSON.stringify(req);
    return fetch(uri, { method: "PUT", headers, body, credentials }).then(
      handleResponse
    );
  }

  MutateGhost(req) {
    const uri = `${this.uriPrefix}/api/v1/ghost/${req.id}`;
    const body = JSON.stringify(req);
    return fetch(uri, { method: "PATCH", headers, body, credentials }).then(
      handleResponse
    );
  }

  QueryGhostList(req) {
    const uri = `${this.uriPrefix}/api/v1/ghost`;
    const body = JSON.stringify(req);
    return fetch(uri, { method, headers, body, credentials }).then(
      handleResponse
    );
  }

  SendNotification(req) {
    const uri = `${this.uriPrefix}/api/v1/notification`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    const body = JSON.stringify(req);
    return fetch(uri, { method: "POST", headers, body, credentials }).then(
      handleResponse
    );
  }

  QueryNotificationList(req) {
    const query = queryStringify(req);
    const uri = `${this.uriPrefix}/api/v1/notification${query}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Jwt-Token": req["X-Jwt-Token"],
    };
    return fetch(uri, { method, headers, credentials }).then(handleResponse);
  }
}

export const platformClient = new Platform();
