const i18n = {
  'en-US': {
    'register.form.title': 'Register a new account',
    'register.form.confirmPassword': 'Please confirm your password',
    'register.form.confirmPasswordEqual': 'Password does not match',
    'register.form.button': 'Register',
    'register.form.email': 'Email',
    'register.form.email.placeholder': 'Please enter your email address',
    'register.form.email.invalid': 'Invalid email address',
    'register.form.password': 'Password',
    'register.form.password.placeholder': 'Please enter your password',
    'register.form.password.invalid': 'Invalid password. Password must be 8~16 characters long and consist of uppercase + lowercase letter and digit.',
    'register.form.confirmLabel': 'Confirm password',
    'register.form.firstName': 'First Name',
    'register.form.firstName.placeholder': 'Please enter your first name',
    'register.form.lastName': 'Last Name',
    'register.form.lastName.placeholder': 'Please enter your last name',
    'register.form.phone': 'Phone',
    'register.form.phone.placeholder': 'Please enter your phone number',
    'register.form.phone.invalid': 'Invalid phone number. Please enter your country code (eg. "+1") and phone number',
    'register.form.serial': '3rd Party Serial Number',
    'register.form.serial.placeholder': 'Please enter your 3rd party serial number',
    'register.form.passport': 'Passport Number',
    'register.form.passport.placeholder': 'Please enter your passport number',
    'register.form.nationalID': 'National ID',
    'register.form.nationalID.placeholder': 'Please enter your national ID number',
    'register.success': 'Successfully registered!',
    'register.error': 'Error',
    'register.banner.slogan': 'VisaHub',
    'register.banner.subSlogan': 'No hassle, just travel',
  },
  'zh-CN': {
    'register.form.title': '注册一个新账号',
    'register.form.confirmPassword': '请确认您的密码',
    'register.form.confirmPasswordEqual': '密码不一致',
    'register.form.button': '注册',
    'register.form.email': '邮箱',
    'register.form.email.placeholder': '请输入邮箱地址',
    'register.form.email.invalid': '无效邮箱地址',
    'register.form.password': '密码',
    'register.form.password.placeholder': '请输入您的密码',
    'register.form.confirmLabel': '确认密码',
    'register.form.password.invalid': '无效密码。密码长度必须为8～16字符，并且包含大小写字母与数字',
    'register.form.firstName': '名',
    'register.form.firstName.placeholder': '请输入您的名',
    'register.form.lastName': '姓',
    'register.form.lastName.placeholder': '请输入您的姓',
    'register.form.phone': '电话号码',
    'register.form.phone.placeholder': '请输入您的电话号码',
    'register.form.phone.invalid': '无效号码。请输入您的国家代码（例如“+1”）和电话号码',
    'register.form.serial': '第三方序列号',
    'register.form.serial.placeholder': '请输入您的第三方序列号',
    'register.form.passport': '护照号码',
    'register.form.passport.placeholder': '请输入您的护照号码',
    'register.form.nationalID': '身份ID',
    'register.form.nationalID.placeholder': '请输入您的身份ID',
    'register.success': '注册成功！',
    'register.error': '错误',
    'register.banner.slogan': 'VisaHub',
    'register.banner.subSlogan': '无烦扰，尽畅游',
  },
};

export default i18n;
