import React, { useContext } from 'react';
import {
  Tooltip,
  Avatar,
  Select,
  Dropdown,
  Menu,
  Divider,
  Message,
  Button,
} from '@arco-design/web-react';
import {
  IconLanguage,
  IconSunFill,
  IconMoonFill,
  IconSettings,
  IconPoweroff,
  IconUser
} from '@arco-design/web-react/icon';
import { GlobalContext } from '@/context';
import useLocale from '@/utils/useLocale';
import Logo from '@/assets/logo-v1.svg';
import IconButton from './IconButton';
import Settings from '../Settings';
import styles from './style/index.module.less';
import { useHistory } from 'react-router-dom';
import { store } from '@/reduxStore';
import { platform } from '@/utils/requestPlaform';
import { DecryptedAuthorities } from '@/utils/encrypter';

function Navbar({ show }: { show: boolean }) {
  const t = useLocale();
  const history = useHistory();
  const authorities = DecryptedAuthorities();
  const { setLang, lang, theme, setTheme } = useContext(GlobalContext);

  async function onMenuItemClick(key) {
    if (key === 'logout') {
      try {
        await platform.SignOut();

        // Clean up
        store.dispatch({
          type: 'update-userInfo',
          payload: { userId: undefined, jwtToken : undefined },
        });
        store.dispatch({
          type: 'update-authorities',
          payload: { authorities: undefined },
        })

        Message.success(t['logout.success']);

        setTimeout(() => {
          history.push('/login');
        }, 1000);
      } catch (e) {
        let errorMsg = t['logout.fail'];
        if (e.response) {
          const json = await e.response.json();
          if (json.message) {
            errorMsg += ' ' + json.message;
          }
        }
        console.log(errorMsg);
        Message.error({content: errorMsg, duration: 6000});
      }
    } else if (key === 'user') {
      history.push('/setting/user');
    } else if (key === 'agency') {
      history.push('/setting/agency');
    }
  }

  if (!show) {
    return (
      <div className={styles['fixed-settings']}>
        <Settings
          trigger={
            <Button icon={<IconSettings />} type="primary" size="large" />
          }
        />
      </div>
    );
  }

  const droplist = (
    <Menu onClickMenuItem={onMenuItemClick}>
      <Menu.Item key="user">
        <IconSettings className={styles['dropdown-icon']} />
        {t['menu.user.setting']}
      </Menu.Item>
      <Divider style={{ margin: '4px 0' }} />
      {authorities && authorities.length > 0 && (
        <>
          <Menu.Item key="agency">
            <IconSettings className={styles['dropdown-icon']} />
            {t['menu.agency.setting']}
          </Menu.Item>
          <Divider style={{ margin: '4px 0' }} />
        </>
      )}
      <Menu.Item key="logout">
        <IconPoweroff className={styles['dropdown-icon']} />
        {t['navbar.logout']}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <div className={styles.logo}>
          <Logo />
          <div className={styles['logo-name']}>VisaHub</div>
        </div>
      </div>
      <ul className={styles.right}>
        <li>
          <Select
            triggerElement={<IconButton icon={<IconLanguage />} />}
            options={[
              { label: '中文', value: 'zh-CN' },
              { label: 'English', value: 'en-US' },
            ]}
            value={lang}
            triggerProps={{
              autoAlignPopupWidth: false,
              autoAlignPopupMinWidth: true,
              position: 'br',
            }}
            trigger="hover"
            onChange={(value) => {
              setLang(value);
            }}
          />
        </li>
        <li>
          <Tooltip
            content={
              theme === 'light'
                ? t['settings.navbar.theme.toDark']
                : t['settings.navbar.theme.toLight']
            }
          >
            <IconButton
              icon={theme !== 'dark' ? <IconMoonFill /> : <IconSunFill />}
              onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
            />
          </Tooltip>
        </li>
        <li>
          <Dropdown droplist={droplist} position="br">
            <Avatar size={32} style={{ cursor: 'pointer' }}>
              <IconUser />
            </Avatar>
          </Dropdown>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
