import React, {
  CSSProperties,
  useRef,
  useState,
  useEffect,
} from 'react';
import useLocale from '@/utils/useLocale';
import {
  Descriptions,
  Card,
  Skeleton,
  Typography,
  Form,
  Grid,
  Select,
  Input,
  InputTag,
  Space,
  Button,
  Message,
  DatePicker,
  InputNumber
} from '@arco-design/web-react';
import { IconDelete, IconPlus, IconUpload } from '@arco-design/web-react/icon';
import locale from './locale';
import { FormInstance } from '@arco-design/web-react/es/Form';
import { useParams } from 'react-router-dom';
import {
  getVisaType,
  getConsularDistrict,
  getSlotType,
  VisaTypeType,
  ConsularDistrictType,
  VisaCountryType,
  SlotType
} from '@/utils/enumTypeNameConverter';
import styles from './style/index.module.less';
import { platform } from '@/utils/requestPlaform';
import { UpdateOrderRequest } from '@/api-lib/platform';
import { Order, Slot, AppointmentAccount, User, Contact } from '@/api-lib/po';
import { useSelector } from 'react-redux';
import { GlobalState } from '@/store';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

interface SlotItemProps {
  cardTitle: string;
  title: string;
  /* eslint-disable-next-line */
  data: any;
  /* eslint-disable-next-line */
  user: any;
  order_status: number;
  priority: number;
  style?: CSSProperties;
  loading?: boolean;
}

function SlotItem(props: SlotItemProps) {
  const t = useLocale(locale);
  const { cardTitle, data, loading, user, priority } = props;
  const visaFormRef = useRef<FormInstance>();
  const slotFormRef = useRef<FormInstance>();
  const userFormRef = useRef<FormInstance>();
  const contactFormRef = useRef<FormInstance>();
  const applicantFormRef = useRef<FormInstance>();
  const additionalContactFormRef = useRef<FormInstance>();
  const { id } = useParams<{ id: string }>();

  const [buttonLoading, setButtonLoading] = useState(false);
  const { jwtToken } = useSelector((state: GlobalState) => state);
  const [selectedCountry, setSelectedCountry] = useState<number>(1);
  const [selectedTimes, setSelectedTimes] = useState<number[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<number[]>([]);
  const [initialSlots, setInitialSlots] = useState([]);

  useEffect(() => {
    setInitialSlots(data.slots);
  }, [data.slots]);

  const handleUpdate = async () => {
    setButtonLoading(true);
    try {
      const visaFormValues = await visaFormRef.current.validate();
      const slotFormValues = await slotFormRef.current.validate();
      const convertedSlotFormValues = slotFormValues.slots.map(element => {
        const start_date = element.date[0];
        const end_date = element.date[1];
        const slot: Slot = {
          start_date: start_date,
          end_date: end_date,
        }

        if (element.times) {
          slot.times = element.times;
        } else if (element.types) {
          slot.types = element.types;
        }

        return slot;
      });

      const userFormValues = userFormRef.current ? await userFormRef.current.validate() : undefined;
      const contactFormValues = await contactFormRef.current.validate();
      const applicantFormValues = applicantFormRef.current ? await applicantFormRef.current.validate() : undefined;

      const order: Order = {
        slots: convertedSlotFormValues,
        visa_type: visaFormValues['visaType'],
        visa_country: selectedCountry,
        consular_country: 1,
        consular_district: visaFormValues['consularDistrict'],
        quantity: visaFormValues['quantity']
      }

      if (userFormValues) {
        const appointmentAccount: AppointmentAccount = {
          email: userFormValues['email'],
          username: userFormValues['userName'],
          password: userFormValues['password'],
        }
        order.appointment_account = appointmentAccount;
      }

      if (applicantFormValues) {
        order.applicants = applicantFormValues['id'];
      }

      const additionalContactFormValues = await additionalContactFormRef.current.validate();
      if (additionalContactFormValues.contacts) {
        const convertedAdditionalFormValues = additionalContactFormValues.contacts.map(element => {
          const phone = element.phone ? element.phone : undefined;
          const email = element.email ? element.email : undefined;

          const contact: Contact = {}
          if (phone) {
            contact.phone = phone;
          }

          if (email) {
            contact.email = email
          }

          return contact;
        });

        order.contacts = convertedAdditionalFormValues
      }

      const user: User = {
        email: contactFormValues['email'],
        phone: contactFormValues['phone'],
      }

      const req: UpdateOrderRequest = {
        id: id,
        order: order,
        user: user,
        "X-Jwt-Token": jwtToken
      }

      await platform.UpdateOrder(req);

      Message.success(t['groupForm.update.success']);
      setTimeout(() => {
        history.go(0);
      }, 1000);
    } catch (e) {
      setButtonLoading(false);
      let errorMsg = t['groupForm.update.fail'];
      if (e.response) {
        const json = await e.response.json();
        if (json.message) {
          errorMsg += ' ' + json.message;
        }
      }
      console.log(errorMsg);
      Message.error({content: errorMsg, duration: 6000});
    }
  };

  const slotBookType = [t['menu.order.slot.selectTime'], t['menu.order.slot.selectType']]
  const availableTimes = ["08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30"]

  const visaData = [
    {
      label: t['order.slot.visaType'],
      value: data?.visa_type ? getVisaType(data?.visa_type) : '-',
    },
    {
      label: t['order.slot.consular'],
      value: data?.consular_district ? getConsularDistrict(data?.consular_district) : '-',
    },
    {
      label: t['order.slot.quantity'],
      value: data?.quantity ? data?.quantity : '-',
    },
    {
      label: t['order.slot.priority'],
      value: priority === 2 ? t['order.slot.priority.yes'] : t['order.slot.priority.no'],
    }
  ];

  /* eslint-disable-next-line */
  const mapDataToSlotsList = (data: any, t: any) => {
    const slotsList = [];

    /* eslint-disable-next-line */
    data?.slots?.forEach((slot: any, index: number) => {
      const data = [
        {
          label: t['order.slot.date'],
          value: slot?.start_date === slot?.end_date ? `${slot?.start_date}` : `${slot?.start_date} ~ ${slot?.end_date}` || '-',
        },
        {
          label: t['order.slot.time'],
          value: slot?.times?.join(", ") || '-',
        },
        {
          label: t['order.slot.type'],
          value: slot?.types?.map(getSlotType).join(", ") || '-',
        },
      ].filter(item => item.value !== '-');

      if (data.length > 0) {
        slotsList.push({
          title: `#${index + 1}`,
          data,
        });
      }
    });

    return slotsList;
  }

  const slotsList = mapDataToSlotsList(data, t);

  /* eslint-disable-next-line */
  const mapDataToTLSList = (data: any, t: any) => {
    const usersList = [];

    usersList.push({
      title: t['order.detail.userInfo'],
      data: [
        {
          label: t['order.detail.userEmail'],
          value: data?.appointment_account?.email || '-',
        },
        {
          label: t['order.detail.userName'],
          value: data?.appointment_account?.username || '-',
        },
        {
          label: t['order.detail.userPassword'],
          value: data?.appointment_account?.password || '-',
        },
      ],
    });

    return usersList;
  }

  /* eslint-disable-next-line */
  const mapDataToContactList = (data: any, t: any) => {
    const contactList = [];

    contactList.push({
      title: t['menu.order.contactInfo'],
      data: [
        {
          label: t['order.detail.userEmail'],
          value: data?.email || '-',
        },
        {
          label: t['menu.order.user.phone'],
          value: data?.phone || '-',
        },
      ],
    });

    return contactList;
  }

  const tlsList = mapDataToTLSList(data, t);
  const contactList = mapDataToContactList(user, t);

  if (loading || data.slots.length === 0) {
    return <div></div>;
  }

  return props.order_status === 1 || props.order_status === 2 || props.order_status === 4 ? (
    <div>
      <Card>
        <Typography.Title heading={6}>
          {t['menu.order.select.country']}
        </Typography.Title>

        <Select
          placeholder={t['menu.order.select.country']}
          defaultValue={1}
          autoWidth={{ minWidth: 200, maxWidth: 400 }}
          style={{ marginBottom: 16 }}
          onChange={(value: number) => setSelectedCountry(value)}
        >
          {VisaCountryType.map((country, index) => (
            <Select.Option key={index} value={index + 1}>
              {country}
            </Select.Option>
          ))}
        </Select>
      </Card>

      <Card>
        <Typography.Title heading={6}>
          {t['menu.order.visaInfo']}
        </Typography.Title>

        <Form layout="vertical" ref={visaFormRef}>
          <Grid.Row gutter={80}>
            <Grid.Col span={6}>
              <Form.Item
                label={t['menu.order.slot.visaType']}
                rules={[{ required: true, message: t['menu.order.select.placeholder'] }]}
                field='visaType'
                initialValue={data?.visa_type}
              >
                <Select defaultValue={data.visa_type} placeholder={t['menu.order.select.placeholder']}>
                  {VisaTypeType.map((visaType, index) => (
                    <Select.Option key={index} disabled={index === 1} value={index + 1}>
                      {visaType}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Grid.Col>
            <Grid.Col span={6}>
              <Form.Item
                label={t['menu.order.slot.consular']}
                rules={[{ required: true, message: t['menu.order.select.placeholder'] }]}
                field='consularDistrict'
                initialValue={data?.consular_district}
              >
                <Select placeholder={t['menu.order.select.placeholder']}>
                  {ConsularDistrictType.map((district, index) => (
                    <Select.Option key={index} disabled={index === 0} value={index + 1}>
                      {district}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Grid.Col>
            <Grid.Col span={6}>
              <Form.Item
                label={t['menu.order.slot.quantity']}
                rules={[{ required: true, message: t['menu.order.slot.quantity.placeholder'] }]}
                field='quantity'
                initialValue={data?.quantity}
              >
                <InputNumber
                  placeholder={t['menu.order.slot.quantity.placeholder']}
                  min={1}
                  max={100}
                />
              </Form.Item>
            </Grid.Col>
          </Grid.Row>
        </Form>
      </Card>

      <Card>
        <Typography.Title heading={6}>
          {t['menu.order.slotInfo']}
        </Typography.Title>

        <Form
          ref={slotFormRef}
          initialValues={{
            slots: data.slots.map(slot => {
              if (slot.times) {
                return {
                  date: [slot.start_date, slot.end_date],
                  bookType: 1,
                  times: slot.times,
                }
              } else if (slot.types) {
                return {
                  date: [slot.start_date, slot.end_date],
                  bookType: 2,
                  types: slot.types
                }
              } else {
                return {
                  date: [slot.start_date, slot.end_date],
                }
              }
            }),
          }}
        >
          <Form.List field='slots'>
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((item, index) => {
                    return (
                      <div key={item.key}>
                        <Form.Item label={'#' + (index + 1)} labelCol={{ flex: 'none' }}>
                          <Space>
                            <Form.Item
                              rules={[{ required: true, message: t['menu.order.select.placeholder'] }]}
                              field={item.field + '.date'}
                              noStyle
                            >
                              <DatePicker.RangePicker
                                clearRangeOnReselect
                                placeholder={t['menu.order.slot.date']}
                                format='YYYY-MM-DD'
                                editable={false}
                                allowClear={false}
                              />
                            </Form.Item>

                            <Form.Item
                              rules={[{ message: t['menu.order.select.placeholder'] }]}
                              field={item.field + '.bookType'}
                              noStyle
                            >
                              <Select
                                addBefore={t['menu.order.slot.bookType']}
                                placeholder={t['menu.order.slot.select.placeholder']}
                                style={{ width: 380 }}
                                onChange={(value: number) => {
                                  const newSelectedTimes = [...selectedTimes];
                                  const newSelectedTypes = [...selectedTypes];

                                  if (value === 1) {
                                    newSelectedTimes[index] = value;
                                    newSelectedTypes[index] = null;
                                  }

                                  if (value === 2) {
                                    newSelectedTimes[index] = null;
                                    newSelectedTypes[index] = value;
                                  }

                                  setSelectedTimes(newSelectedTimes);
                                  setSelectedTypes(newSelectedTypes);
                                }}
                                onClear={() => {
                                  const newSelectedTimes = [...selectedTimes];
                                  const newSelectedTypes = [...selectedTypes];
                                  const newInitialSlots = [...initialSlots]
                                  newSelectedTimes[index] = null;
                                  newSelectedTypes[index] = null;
                                  newInitialSlots[index] = null;
                                  setSelectedTimes(newSelectedTimes);
                                  setSelectedTypes(newSelectedTypes);
                                  setInitialSlots(newInitialSlots)
                                }}
                                allowClear
                              >
                                {slotBookType.map((bookType, index) => (
                                  <Select.Option key={index} value={index + 1}>
                                    {bookType}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>

                            {(initialSlots[index]?.times || selectedTimes[index]) && (
                              <Form.Item
                                rules={[{ required: true, message: t['menu.order.select.placeholder'] }]}
                                field={item.field + '.times'}
                                noStyle
                              >
                                <Select
                                  addBefore={t['menu.order.slot.time']}
                                  mode='multiple'
                                  placeholder={t['menu.order.select.placeholder']}
                                  maxTagCount={3}
                                  style={{ width: 250 }}
                                >
                                  {availableTimes.map((option) => (
                                    <Select.Option key={option} value={option}>
                                      {option}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            )}

                            {(initialSlots[index]?.types || selectedTypes[index]) && (
                              <Form.Item
                                rules={[{ required: true, message: t['menu.order.select.placeholder'] }]}
                                field={item.field + '.types'}
                                noStyle
                              >
                                <Select
                                  addBefore={t['menu.order.slot.type']}
                                  mode='multiple'
                                  placeholder={t['menu.order.select.placeholder']}
                                  maxTagCount={2}
                                  style={{ width: 250 }}
                                  defaultValue={['WeekdayNormal']}
                                >
                                  {SlotType.map((option, index) => (
                                    <Select.Option key={index} disabled={index === 1 || index === 2} value={index + 1}>
                                      {option}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            )}

                            {fields.length > 1 && (
                              <Button
                                icon={<IconDelete />}
                                shape='circle'
                                status='danger'
                                onClick={() => {
                                  remove(index);
                                  setSelectedTimes(selectedTimes.filter((_, i) => i !== index));
                                  setSelectedTypes(selectedTypes.filter((_, i) => i !== index));
                                  setInitialSlots(initialSlots.filter((_, i) => i !== index))
                                }}
                              ></Button>
                            )}
                          </Space>
                        </Form.Item>
                      </div>
                    );
                  })}
                  <Form.Item>
                    <Button type='primary'
                      onClick={() => {
                        add();
                        setSelectedTimes([...selectedTimes, null]);
                        setSelectedTypes([...selectedTypes, null]);
                        setInitialSlots([...initialSlots, null])
                      }}
                    >
                      {t['menu.order.slot.add']}
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </Card>

      {selectedCountry === 1 && (
        <Card>
          <Form layout="vertical" ref={userFormRef}>
            <Typography.Title heading={6}>
              {t['menu.order.userInfo']}
            </Typography.Title>
            <Grid.Row gutter={80}>
              <Grid.Col span={6}>
                <Form.Item
                  label={t['menu.order.email']}
                  field='email'
                  rules={[{ required: true, message: t['menu.order.email.placeholder'] }]}
                  initialValue={data?.appointment_account.email}
                >
                  <Input
                    placeholder={t['menu.order.email.placeholder']}
                    onBlur={() => {
                      const userEmail = userFormRef.current.getFieldValue('email');
                      const contactEmail = contactFormRef.current.getFieldValue('email');
                      if (!contactEmail) {
                        contactFormRef.current.setFieldsValue({
                          email: userEmail,
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Grid.Col>
              <Grid.Col span={6}>
                <Form.Item
                  label={t['menu.order.password']}
                  field='password'
                  rules={[{ required: true, message: t['menu.order.password.placeholder'] }]}
                  initialValue={data?.appointment_account.password}
                >
                  <Input.Password placeholder={t['menu.order.password.placeholder']} />
                </Form.Item>
              </Grid.Col>
            </Grid.Row>
          </Form>
        </Card>
      )}

      <Card>
        <Form layout="vertical" ref={contactFormRef} className={styles['form-group']}>
          <Typography.Title heading={6}>
            {t['menu.order.contactInfo']}
          </Typography.Title>
          <Grid.Row gutter={80}>
            <Grid.Col span={6}>
              <Form.Item
                label={t['menu.order.email']}
                field='email'
                rules={[{ required: true, message: t['menu.order.email.placeholder'] }]}
                initialValue={user?.email}
              >
                <Input placeholder={t['menu.order.email.placeholder']} />
              </Form.Item>
            </Grid.Col>
            <Grid.Col span={6}>
              <Form.Item
                label={t['menu.order.user.phone']}
                rules={[{
                  message: t['menu.order.user.phone.invalid'],
                  validator: (v, cb) => {
                    if (!v) {
                      return cb(null)
                    }
                    const phoneNumber = parsePhoneNumberFromString(v);
                    if (!phoneNumber) {
                      return cb(t['menu.order.user.phone.invalid'])
                    }
                    if (!phoneNumber.isValid()) {
                      return cb(t['menu.order.user.phone.invalid'])
                    }
                    cb(null)
                  }
                }]}
                field="phone"
                initialValue={user?.phone}
              >
                <Input placeholder={t['menu.order.user.phone.placeholder']} />
              </Form.Item>
            </Grid.Col>
          </Grid.Row>
        </Form>
      </Card>

      {selectedCountry === 2 && (
        <Card>
          <Form layout="vertical" ref={applicantFormRef} className={styles['form-group']}>
            <Typography.Title heading={6}>
              {t['menu.order.applicant']}
            </Typography.Title>
            <Grid.Row gutter={80}>
              <Grid.Col span={6}>
                <Form.Item
                  label={t['menu.order.applicant.id']}
                  field='id'
                  initialValue={data?.applicants}
                >
                  <InputTag placeholder={t['menu.order.applicant.id.placeholder']} animation={false} readOnly={true} allowClear />
                </Form.Item>
              </Grid.Col>
            </Grid.Row>
          </Form>
        </Card>
      )}

      <Card>
        <Typography.Title heading={6}>
          {t['menu.order.additional_contact.info']}
        </Typography.Title>

        <Form
          ref={additionalContactFormRef}
          initialValues={{
            contacts: data?.contacts,
          }}
        >
          <Form.List field='contacts'>
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((item, index) => {
                    return (
                      <div key={item.key}>
                        <Form.Item label={'#' + (index + 1)} labelCol={{ flex: 'none' }}>
                          <Space>
                            <Form.Item
                              label={t['menu.order.email']}
                              field={item.field + '.email'}
                              rules={[{ message: t['menu.order.email.placeholder'] }]}
                            >
                              <Input placeholder={t['menu.order.email.placeholder']} style={{ width: 350 }} />
                            </Form.Item>

                            <Form.Item
                              label={t['menu.order.user.phone']}
                              rules={[{
                                message: t['menu.order.user.phone.invalid'],
                                validator: (v, cb) => {
                                  if (!v) {
                                    return cb(null)
                                  }
                                  const phoneNumber = parsePhoneNumberFromString(v);
                                  if (!phoneNumber) {
                                    return cb(t['menu.order.user.phone.invalid'])
                                  }
                                  if (!phoneNumber.isValid()) {
                                    return cb(t['menu.order.user.phone.invalid'])
                                  }
                                  cb(null)
                                }
                              }]}
                              field={item.field + '.phone'}
                            >
                              <Input placeholder={t['menu.order.user.phone.placeholder']} style={{ width: 350 }} />
                            </Form.Item>

                            {(
                              <Button
                                icon={<IconDelete />}
                                shape='circle'
                                status='danger'
                                onClick={() => remove(index)}
                                style={{ marginBottom: 20 }}
                              ></Button>
                            )}
                          </Space>
                        </Form.Item>
                      </div>
                    );
                  })}

                  <Form.Item>
                    <Button type='primary'
                      icon={<IconPlus />}
                      onClick={() => { add(); }}
                    >
                      {t['menu.order.additional_contact.add']}
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </Card>

      <div className={styles.actions}>
        <Space>
          <Button
            type="primary"
            icon={<IconUpload />}
            onClick={handleUpdate}
            loading={buttonLoading}
            size="large"
          >
            {t['groupForm.update']}
          </Button>
        </Space>
      </div>
    </div>
  ) : (
    <div>
      <Card>
        <Typography.Title heading={6}>
          {t['order.visa.info']}
        </Typography.Title>

        <Descriptions
          colon=":"
          labelStyle={{ textAlign: 'left' }}
          column={1}
          border
          size='large'
          data={
            loading
              ? visaData.map((item) => ({
                ...item,
                value: (
                  <Skeleton
                    text={{ rows: 1, style: { width: '200px' } }}
                    animation
                  />
                ),
              }))
              : visaData
          }
          style={{ marginTop: '20px' }}
        />
      </Card>

      <Card>
        <Typography.Title heading={6}>
          {cardTitle}
        </Typography.Title>

        {slotsList.map(({ title: blockTitle, data: blockData }, index) => (
          <Descriptions
            key={`${index}`}
            colon=":"
            labelStyle={{ textAlign: 'left' }}
            column={1}
            title={blockTitle}
            border
            size='large'
            data={
              loading
                ? blockData.map((item) => ({
                  ...item,
                  value: (
                    <Skeleton
                      text={{ rows: 1, style: { width: '200px' } }}
                      animation
                    />
                  ),
                }))
                : blockData
            }
            style={{ marginTop: '20px' }}
          />
        ))}
      </Card>

      <Card>
        {tlsList.map(({ title: blockTitle, data: blockData }, index) => (
          <Descriptions
            key={`${index}`}
            colon=":"
            labelStyle={{ textAlign: 'left' }}
            column={1}
            title={blockTitle}
            border
            size='large'
            data={
              loading
                ? blockData.map((item) => ({
                  ...item,
                  value: (
                    <Skeleton
                      text={{ rows: 1, style: { width: '200px' } }}
                      animation
                    />
                  ),
                }))
                : blockData
            }
          />
        ))}
      </Card>

      <Card>
        {contactList.map(({ title: blockTitle, data: blockData }, index) => (
          <Descriptions
            key={`${index}`}
            colon=":"
            labelStyle={{ textAlign: 'left' }}
            column={1}
            title={blockTitle}
            border
            size='large'
            data={
              loading
                ? blockData.map((item) => ({
                  ...item,
                  value: (
                    <Skeleton
                      text={{ rows: 1, style: { width: '200px' } }}
                      animation
                    />
                  ),
                }))
                : blockData
            }
          />
        ))}
      </Card>
    </div>
  );
}

export default SlotItem;
