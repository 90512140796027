const i18n = {
  'en-US': {
    'order.detail.status': 'Order Status',
    'order.detail.title': 'Order Detail',
    'order.detail.slot': 'Slot Information',
    'order.detail.userInfo': 'Visa System Account (TLS)',
    'order.detail.userEmail': 'User Email',
    'order.detail.userName': 'User Name',
    'order.detail.userPassword': 'User Password',
    'order.detail.firstName': 'First Name',
    'order.detail.lastName': 'Last Name',
    'order.detail.passport': 'Passport',
    'order.detail.suspend': 'Suspend order',
    'order.detail.suspend.success': 'Successfully suspended',
    'order.detail.suspend.fail': 'Failed to suspend',
    'order.detail.resume': 'Resume order',
    'order.detail.resume.success': 'Successfully resumed',
    'order.detail.resume.fail': 'Failed to resume',
    'order.detail.cancel': 'Cancel order',
    'order.detail.cancel.success': 'Successfully cancelled',
    'order.detail.cancel.fail': 'Failed to cancel',
    'order.status.pending': 'Pending',
    'order.status.suspended': 'Account Suspended',
    'order.status.failed': 'Payment Failed',
    'order.status.working': 'Working',
    'order.status.cancelled': 'Cancelled',
    'order.status.submitted': 'Slot Booked',
    'order.status.finished': 'Finished',
    'order.slot.visaCountry': 'Visa Country',
    'order.slot.visaType': 'Visa Type',
    'order.slot.consular': 'Consular District',
    'order.slot.date': 'Date',
    'order.slot.time': 'Times',
    'order.slot.type': 'Types',
    'order.slot.quantity': 'Number of people',
    'order.slot.priority': 'Is Priority',
    'order.slot.priority.yes': 'Yes',
    'order.slot.priority.no': 'No',
    'order.visa.info': 'Visa Information',
    'menu.order.select.placeholder': 'Please select',
    'menu.order.slot.select.placeholder': 'Accept all by default',
    'menu.order.visaInfo': 'Visa Information',
    'menu.order.slotInfo': 'Slot Information',
    'menu.order.slot.visaCoutnry': 'Visa Country',
    'menu.order.slot.visaType': 'Visa Type',
    'menu.order.slot.consular': 'Consular District',
    'menu.order.slot.quantity': 'Number of People',
    'menu.order.slot.quantity.placeholder': 'Please enter the number of people',
    'menu.order.select.country': 'Select Visa Country',
    'menu.order.userInfo': 'Visa System Account (TLS)',
    'menu.order.contactInfo': 'Client Contact Information (For slot status notification)',
    'menu.order.email': 'Email',
    'menu.order.email.placeholder': 'Please enter user email address',
    'menu.order.userName': 'User Name',
    'menu.order.userName.placeholder': 'Please enter user name',
    'menu.order.password': 'User Password',
    'menu.order.password.placeholder': 'Please enter user password',
    'menu.order.slot.date': 'Please select',
    'menu.order.slot.bookType': 'Booking Method',
    'menu.order.slot.time': 'Time',
    'menu.order.slot.type': 'Type',
    'menu.order.slot.selectTime': 'Specify Time',
    'menu.order.slot.selectType': 'Specify Type',
    'menu.order.slot.add': 'Add slot',
    'menu.order.user.invalid': 'Please provide either user email or user name',
    'menu.order.user.phone': 'Phone Number',
    'menu.order.user.phone.invalid': 'Invalid phone number. Please enter your country code (eg. "+1") and phone number',
    'menu.order.user.phone.placeholder': 'Please enter user phone number',
    'menu.order.applicant': 'Applicant Information (If user applicant ID is known)',
    'menu.order.applicant.id': 'Applicant ID',
    'menu.order.applicant.id.placeholder': 'Please input applicant ID and press Enter to add',
    'menu.order.additional_contact.info': 'Additional Contact Information (Optional)',
    'menu.order.additional_contact.add': 'Add additional contact',
    'groupForm.update': 'Update',
    'groupForm.update.success': 'Successfully updated order',
    'groupForm.update.fail': 'Failed to update order',
  },
  'zh-CN': {
    'order.detail.status': '订单状态',
    'order.detail.title': '订单详情',
    'order.detail.slot': '预约时间信息',
    'order.detail.userInfo': 'TLS 账号信息',
    'order.detail.userEmail': '用户邮箱',
    'order.detail.userName': '用户名',
    'order.detail.userPassword': '用户密码',
    'order.detail.firstName': '名',
    'order.detail.lastName': '姓',
    'order.detail.passport': '护照号',
    'order.detail.suspend': '暂停订单',
    'order.detail.suspend.success': '暂停成功',
    'order.detail.suspend.fail': '暂停失败',
    'order.detail.resume': '恢复订单',
    'order.detail.resume.success': '恢复成功',
    'order.detail.resume.fail': '恢复失败',
    'order.detail.cancel': '取消订单',
    'order.detail.cancel.success': '取消成功',
    'order.detail.cancel.fail': '取消失败',
    'order.status.pending': '等待中',
    'order.status.suspended': '暂停',
    'order.status.failed': '支付失败',
    'order.status.working': '处理中',
    'order.status.cancelled': '已取消',
    'order.status.submitted': '已占位',
    'order.status.finished': '完成',
    'order.slot.visaCountry': '签证国家',
    'order.slot.visaType': '签证类型',
    'order.slot.consular': '使馆区域',
    'order.slot.quantity': '面签人数',
    'order.slot.priority': '是否加急',
    'order.slot.priority.yes': '是',
    'order.slot.priority.no': '否',
    'menu.order.slot.quantity': '面签人数',
    'menu.order.slot.quantity.placeholder': '请输入面签人数',
    'order.slot.date': '日期',
    'order.slot.time': '时间',
    'order.slot.type': '类型',
    'order.visa.info': '签证信息',
    'menu.order.select.placeholder': '请选择',
    'menu.order.slot.select.placeholder': '可选预约类型，不选视为都接受',
    'menu.order.visaInfo': '签证信息',
    'menu.order.slotInfo': '预约时间信息',
    'menu.order.slot.visaCountry': '签证国家',
    'menu.order.slot.visaType': '签证类型',
    'menu.order.slot.consular': '使馆区域',
    'menu.order.select.country': '选择签证国家',
    'menu.order.userInfo': 'TLS 账号信息',
    'menu.order.contactInfo': '客户联系信息（用于系统通知联系用户抢位订单状态）',
    'menu.order.email': '邮箱',
    'menu.order.email.placeholder': '请输入用户邮箱地址',
    'menu.order.userName': '用户名',
    'menu.order.userName.placeholder': '请输入用户名',
    'menu.order.password': '密码',
    'menu.order.password.placeholder': '请输入用户密码',
    'menu.order.slot.date': '请选择',
    'menu.order.slot.bookType': '预约方式',
    'menu.order.slot.time': '时间',
    'menu.order.slot.type': '类型',
    'menu.order.slot.selectTime': '指定时间',
    'menu.order.slot.selectType': '指定类型',
    'menu.order.slot.add': '添加时间',
    'menu.order.user.invalid': '请提供用户邮箱或用户名',
    'menu.order.user.phone': '电话号码',
    'menu.order.user.phone.invalid': '无效的电话号码。请输入您的国家代码（例如“+1”）和电话号码',
    'menu.order.user.phone.placeholder': '请输入用户电话号码',
    'menu.order.applicant': '申请人信息（如果已知用户申请人ID）',
    'menu.order.applicant.id': '申请人ID',
    'menu.order.applicant.id.placeholder': '请输入申请人ID并按回车键添加',
    'menu.order.additional_contact.info': '额外联系人信息（可选）',
    'menu.order.additional_contact.add': '添加额外联系人',
    'groupForm.update': '更新',
    'groupForm.update.success': '更新订单成功',
    'groupForm.update.fail': '更新订单失败',
  },
};

export default i18n;
