import {
  Form,
  Input,
  Button,
  Message
} from '@arco-design/web-react';
import React, { useContext, useState } from 'react';
import { GlobalContext } from '@/context';
import useLocale from '@/utils/useLocale';
import locale from './locale';
import styles from './style/index.module.less';
import { useHistory } from 'react-router-dom';
import { platform } from '@/utils/requestPlaform';
import { SignUpRequest } from '@/api-lib/platform';
import { User } from '@/api-lib/po';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default function RegisterForm() {
  const t = useLocale(locale);
  const history = useHistory();
  const [form] = Form.useForm();
  const { lang } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  return (
    <div className={styles['login-form-wrapper']}>
      <div className={styles['login-form-title']}>{t['register.form.title']}</div>
      <Form
        form={form}
        style={{ width: 320, marginTop: '8px' }}
        labelCol={{ span: lang === 'en-US' ? 12 : 6, flex: 'none' }}
        wrapperCol={{ span: 24 }}
        
        autoComplete='off'
        onSubmit={async () => {
          setLoading(true);

          try {
            const user: User = {
              email: form.getFieldValue('email'),
              password: form.getFieldValue('password'),
              phone: form.getFieldValue('phone'),
              serial: form.getFieldValue('serial'),
            }

            const req: SignUpRequest = {
              user: user
            }

            await platform.SignUp(req);
            Message.success(t['register.success']);
            setTimeout(() => {
              history.push('/login');
            }, 1000);
          } catch (e) {
            setLoading(false);
            let errorMsg = t['register.error'];
            if (e.response) {
              const json = await e.response.json();
              if (json.message) {
                errorMsg += ' ' + json.message;
              }
            }
            console.log(errorMsg);
            Message.error({content: errorMsg, duration: 6000});
          }
        }}
      >
        <Form.Item
          label={t['register.form.email']}
          field='email'
          rules={[{
            required: true,
            validator: (v, cb) => {
              if (!v) {
                return cb(t['register.form.email.invalid'])
              } else if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(v)) {
                return cb(t['register.form.email.invalid'])
              }
              cb(null)
            }
          }]}>
          <Input placeholder={t['register.form.email.placeholder']} />
        </Form.Item>
        <Form.Item
          label={t['register.form.password']}
          field='password'
          rules={[{
            required: true,
            validator: (v, cb) => {
              if (!v) {
                return cb(t['register.form.password.invalid'])
              } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/.test(v)) {
                return cb(t['register.form.password.invalid'])
              }
              cb(null)
            }
          }]}>
          <Input.Password type='password' placeholder={t['register.form.password.placeholder']} />
        </Form.Item>
        <Form.Item
          label={t['register.form.confirmLabel']}
          field='confirmPassword'
          dependencies={['password']}
          rules={[{
            required: true,
            validator: (v, cb) => {
              if (!v) {
                return cb(t['register.form.confirmPassword'])
              } else if (form.getFieldValue('password') !== v) {
                return cb(t['register.form.confirmPasswordEqual'])
              }
              cb(null)
            }
          }]}
        >
          <Input.Password type='password' placeholder={t['register.form.confirmPassword']} />
        </Form.Item>
        <Form.Item
          label={t['register.form.phone']}
          field='phone'
          rules={[{
            required: true,
            validator: (v, cb) => {
              if (!v) {
                return cb(null)
              }
              const phoneNumber = parsePhoneNumberFromString(v);
              if (!phoneNumber) {
                return cb(t['register.form.phone.invalid'])
              }
              if (!phoneNumber.isValid()) {
                return cb(t['register.form.phone.invalid'])
              }
              cb(null)
            }
          }]}>
            <Input placeholder={t['register.form.phone.placeholder']}/>
        </Form.Item>
        <Form.Item
          label={t['register.form.serial']}
          field='serial'
          rules={[{
            message: t['register.form.serial.placeholder']
          }]}>
          <Input placeholder={t['register.form.serial.placeholder']} />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' long loading={loading}>
            {t['register.form.button']}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
