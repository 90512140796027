import {
  Form,
  Input,
  Button,
  Space,
  Message
} from '@arco-design/web-react';
import { FormInstance } from '@arco-design/web-react/es/Form';
import { IconLock, IconUser } from '@arco-design/web-react/icon';
import React, {
  useRef,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';
import useLocale from '@/utils/useLocale';
import locale from './locale';
import styles from './style/index.module.less';
import { platform } from '@/utils/requestPlaform';
import { SignInRequest } from '@/api-lib/platform';
import { Authority } from '@/api-lib/po';
import { store } from '@/reduxStore';
import { encryptAuthorities } from '@/utils/encrypter';

export default function LoginForm() {
  const formRef = useRef<FormInstance>();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const t = useLocale(locale);

  async function afterLoginSuccess(userId: string, authorities?: Array<Authority>, jwtToken?: string) {
    try {
      store.dispatch({
        type: 'update-userInfo',
        payload: { userId: userId, jwtToken: jwtToken },
      });
      store.dispatch({
        type: 'update-authorities',
        payload: { authorities: authorities ? encryptAuthorities(authorities) : undefined },
      })

      history.push('/');
    } catch (e) {
      console.log(e.message)
    }
  }

  async function onSubmitClick() {
    try {
      const validatedValues = await formRef.current.validate()
      const req: SignInRequest = {
        email: validatedValues['email'],
        password: validatedValues['password'],
      }
  
      const response = await platform.SignIn(req);
      setErrorMessage('');
      setLoading(true);
      await afterLoginSuccess(response.data.id, response.data.authorities, response.data['x-jwt-token']);
    } catch (e) {
      setLoading(false);
      setErrorMessage(t['login.form.error']);
      let errorMsg = t['login.form.error'];
      if (e.response) {
        const json = await e.response.json();
        if (json.message) {
          errorMsg += ' ' + json.message;
        }
      }
      console.log(errorMsg);
      Message.error({content: errorMsg, duration: 6000});
    }    
  }

  function onRegisterClick() {
    history.push('/register');
  }

  return (
    <div className={styles['login-form-wrapper']}>
      <div className={styles['login-form-title']}>{t['login.form.title']}</div>
      <div className={styles['login-form-error-msg']}>{errorMessage}</div>
      <Form
        className={styles['login-form']}
        layout="vertical"
        ref={formRef}
      >
        <Form.Item
          field="email"
          rules={[{ required: true, message: t['login.form.email.errMsg'] }]}
        >
          <Input
            prefix={<IconUser />}
            placeholder={t['login.form.email.placeholder']}
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Form.Item
          field="password"
          rules={[{ required: true, message: t['login.form.password.errMsg'] }]}
        >
          <Input.Password
            prefix={<IconLock />}
            placeholder={t['login.form.password.placeholder']}
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Space size={16} direction="vertical">
          <Button type="primary" long onClick={onSubmitClick} loading={loading}>
            {t['login.form.login']}
          </Button>
          <Button
            type="text"
            long
            className={styles['login-form-register-btn']}
            onClick={onRegisterClick}
          >
            {t['login.form.register']}
          </Button>
        </Space>
      </Form>
    </div>
  );
}
