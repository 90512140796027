import CryptoJS from 'crypto-js';
import { GlobalState } from '@/store';
import { useSelector } from 'react-redux';
import { Authority } from '@/api-lib/po';

export const encryptAuthorities = (authorities: Array<Authority>) => {
    const jsonData = JSON.stringify(authorities);
    return CryptoJS.AES.encrypt(jsonData, 'authorities').toString();
}

export const DecryptedAuthorities = () => {
    const authorities = useSelector((state: GlobalState) => state.authorities);
    if (!authorities) {
        return [];
    }
    const bytes = CryptoJS.AES.decrypt(authorities, 'authorities');
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(originalText);
}