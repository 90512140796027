import defaultSettings from '../settings.json';
import { Authority } from '@/api-lib/po';

export interface GlobalState {
  settings?: typeof defaultSettings;
  userId?: string;
  authorities?: Array<Authority>;
  jwtToken?: string;
  stateLoading?: boolean;
  lastSelectedAuthority?: string;
}

const initialState: GlobalState = {
  settings: defaultSettings,
  stateLoading: true,
  userId: undefined,
};

export default function store(state = initialState, action) {
  switch (action.type) {
    case 'update-settings': {
      const { settings } = action.payload;
      return {
        ...state,
        settings,
      };
    }
    case 'update-userInfo': {
      const { userId, jwtToken } = action.payload;
      return {
        ...state,
        userId,
        jwtToken
      };
    }
    case 'update-authorities': {
      const { authorities } = action.payload;
      return {
        ...state,
        authorities
      };
    }
    case 'update-lastSelectedAuthority': {
      const { lastSelectedAuthority } = action.payload;
      return {
        ...state,
        lastSelectedAuthority
      };
    }
    case 'persist/REHYDRATE':
      return {
        ...state,
        stateLoading: false,
      };
    default:
      return state;
  }
}
