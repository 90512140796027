/* eslint-disable */
// @ts-nocheck

export const UserType = {
  Unspecified: 0,
  Client: 1,
  Operator: 2,
  Supervisor: 3,
  Manager: 4,
  Admin: 5,
};

export const SexType = {
  Unspecified: 0,
  Male: 1,
  Female: 2,
};

export const MarriageType = {
  Unspecified: 0,
  Single: 1,
  Married: 2,
  Divorced: 3,
  Widowed: 4,
  CivilPartnertship: 5,
};

export const PassportType = {
  Unspecified: 0,
  Regular: 1,
  Diplomatic: 2,
  Official: 3,
  Service: 4,
  Alien: 5,
  Seaman: 6,
  SAR: 7,
};

export const TravelFeeCoverageType = {
  Unspecified: 0,
  SelfCovered: 1,
  OthersCovered: 2,
};

export const AgencyType = {
  Unspecified: 0,
  Local: 1,
  Taobao: 2,
  Redbook: 3,
  ThirdParty: 4,
};

export const SlotStatus = {
  Unspecified: 0,
  Pending: 1,
  Available: 2,
  OutOfSotck: 3,
};

export const VisaCountry = {
  Unspecified: 0,
  France: 1,
  Spain: 2,
};

export const VisaType = {
  Unspecified: 0,
  FR_Visit_Short: 1,
  FR_Visit_Long: 2,
};

export const ConsularCountry = {
  Unspecified: 0,
  UnitedKingdom: 1,
};

export const ConsularDistrict = {
  Unspecified: 0,
  UK_Edinburgh: 1,
  UK_London: 2,
  UK_Manchester: 3,
};

export const Priority = {
  Unspecified: 0,
  Normal: 1,
  Urgent: 2,
  Customized: 3,
};

export const OrderStatus = {
  Unspecified: 0,
  Pending: 1,
  Suspended: 2,
  Working: 3,
  Cancelled: 4,
  Submitted: 5,
  Failed: 6,
  Finished: 7,
  Checking: 8,
};

export const JobStatus = {
  Unspecified: 0,
  Pending: 1,
  Cancelled: 2,
  Working: 3,
  Submitted: 4,
  Accepted: 5,
  Finished: 6,
  Failed: 7,
};

export const PaymentStatus = {
  Unspecified: 0,
  Pending: 1,
  Working: 2,
  Submitted: 3,
  Finished: 4,
  Failed: 5,
};

export const AccountStatus = {
  Unspecified: 0,
  Pending: 1,
  Valid: 2,
  Unauthorized: 3,
  Expired: 4,
  Incomplete: 5,
};

export const ChartType = {
  Unspecified: 0,
  Graph: 1,
  Circle: 2,
  Digital: 3,
};

export const PartitionType = {
  Unspecified: 0,
  Year: 1,
  Month: 2,
  Day: 3,
  Hour: 4,
};

export const GroupByType = {
  Unspecified: 0,
  AgencyType: 1,
  AgencyName: 2,
  Priority: 3,
  OrderStatus: 4,
};

export const StatisticsType = {
  Unspecified: 0,
  Count: 1,
  Sum: 2,
  Avg: 3,
  Max: 4,
  Min: 5,
};

export const AggregateType = {
  Unspecified: 0,
  Record: 1,
  Price: 2,
};

export const SlotType = {
  Unspecified: 0,
  WeekdayNormal: 1,
  WeekdayPrime: 2,
  Weekend: 3,
};

export const ContactType = {
  Unspecific: 0,
  Email: 1,
  Call: 2,
  Message: 3,
};
